<template>
    <div class="layout">
        <Layout>
            <Layout>
                <Sider width="200">
                    <BuyManageMenu ref="buyManageMenu" @func="getMsgFormMenu" :active="title_seclectd" :menuName="menuName"></BuyManageMenu>
                </Sider>
                <Content>
                    <NavTag ref="navTag" @func="getMsgFormMenu"></NavTag>
                    <BuyList v-if="title_seclectd == 1"></BuyList>
                    <!--<StockLackList v-if="title_seclectd == 2"></StockLackList>-->
                    <BuyReturns v-if="title_seclectd == 3"></BuyReturns>
                </Content>
            </Layout>
        </Layout>
    </div>
</template>

<script>
	import BuyManageMenu from '@/components/menu/BuyManageMenu.vue';
	import BuyList from '@/views/buyManage/BuyList.vue';
	import StockLackList from '@/views/buyManage/StockLackList.vue';
	import BuyReturns from '@/views/buyManage/BuyReturns.vue';
  import NavTag from '@/components/menu/NavTags.vue';


  export default {
		name: "BuyManage",
		components: {
			BuyManageMenu,
			BuyList,
      StockLackList,
			BuyReturns,
      NavTag
		},
		data() {
			return {
				title_seclectd: "1",
        menuName:"BuyManage",
			};
		},
    mounted() {
      this.title_seclectd =localStorage.getItem(this.menuName)?localStorage.getItem(this.menuName):"1";

      this.setNavInfo(this.title_seclectd)
    },
		methods: {
			getMsgFormMenu(data){
				this.title_seclectd = data;
        this.setNavInfo(data)
      },
      setNavInfo(data) {
        this.$refs.navTag.setNavList(this.$route.path, this.title_seclectd)
        this.$refs.buyManageMenu.setNavInfo(data)
      }
		},
	};
</script>

<style scoped>
    .ivu-layout {
        background-color:transparent;
    }
    .ivu-layout-sider {
        background-color:transparent;
    }
    .ivu-menu{
        height:100%;
    }

    .laypout {
        display: flex;
    }
</style>
