import request from '@/plugins/http'

export function createBuyOrder(param) {
    let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        emulateJSON: true
    };  //添加请求头
    return request.post('/api/buy/createBuyOrder', param, config);
}

export function arriveBuyOrderAndIn(param) {
    return request.post('/api/buy/arriveBuyOrderAndIn', param);
}

export function apiPayBuyOperate(param) {
    return request.post('/api/buy/payBuyOperate', param);
}

export function apiPayReturnBuyOperate(param) {
    return request.post('/api/buy/payReturnBuyOperate', param);
}

export function apiCheckImportGoodsValid(param) {
    return request.post('/api/goods/checkImportGoodsValid', param);
}

export function apiSetBuyPayStatusEnd(param) {
    return request.post('/api/buy/setBuyPayStatusEnd', param);
}

export function apiSetBuyReturnPayStatusEnd(param) {
    return request.post('/api/buy/setBuyReturnPayStatusEnd', param);
}










