import request from '@/plugins/http'

export function apiCreatePayDesc(param) {
    return request.post('/api/setting/createPayDesc', param);
}

export function apiGetPayDescList(param) {
    return request.post('/api/setting/getPayDescList', param);
}

export function apiDeletePayDesc(param) {
    return request.post('/api/setting/deletePayDesc', param);
}

export function apiGetPayDescAll(param) {
    return request.post('/api/setting/getPayDescAll', param);
}


