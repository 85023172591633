import request from '@/plugins/http'

export function apiSaveProject(param) {
    return request.post('/api/setting/saveProject', param);
}

export function apiGetProjectList(param) {
    return request.post('/api/setting/getProjectList', param);
}

export function apiValidProject(param) {
    return request.post('/api/setting/validProject', param);
}

export function apiInValidProject(param) {
    return request.post('/api/setting/inValidProject', param);
}

export function apiDeleteProjectInfo(param) {
    return request.post('/api/setting/deleteProject', param);
}

export function apiGetProjectAll() {
    return request.post('/api/setting/getProjectAll');
}

export function apiGetProjectMainNo() {
    return request.post('/api/setting/getProjectMainNo');
}




