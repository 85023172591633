<template>
  <div id="clientInfo">
    <div id="panel-header">
      <span class="top-title">采购退货</span>
      <!--暂时关闭采购退货按钮-->
      <!--<Button type="primary" class="client_create" @click="create('clientFormItem')">添加退货单</Button>-->
    </div>
    <!--列表筛选条件-->
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" :model="formItem" inline>
          <FormItem prop="main_no">
            <Input @on-blur="searchSubmit" v-model="formItem.main_no" placeholder="请填写退货编号" class="w-200"/>
          </FormItem>

          <FormItem prop="buy_no">
            <Input @on-blur="searchSubmit" v-model="formItem.buy_no" placeholder="请填写关联单号" class="w-200"/>
          </FormItem>

          <FormItem prop="created_at">
            <DatePicker type="daterange" @on-change="timeChange" placement="bottom-end"
                        placeholder="请选择创建时间" class="w-200" v-model="formItem.created_at"></DatePicker>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">
              清除
            </Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <!--列表-->
    <div id="list" class="buy_returns">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="main_no">
          <strong @click="checkOrderInfo(row)" class="pointer">{{ row.main_no }}</strong>
        </template>
        <template slot-scope="{ row }" slot="status">
          <Tag :style="getStatusColorTg(row.status)" class="white-font-color">{{ getStatusText(row.status) }}</Tag>
        </template>
        <template slot-scope="{ row }" slot="pay_status">
          <span class="pointer" :style="getPayStatusColor(row.pay_status)" @click="setPayStatusFinish(row)">{{ getPayStatusText(row.pay_status) }}</span>
        </template>
        <template slot-scope="{ row }" slot="buy_no">
          <span class="pointer data-copy" @click="copyData(row.buy_no)">{{row.buy_no}}<Icon type="ios-copy-outline"/></span>
        </template>
        <template slot-scope="{ row }" slot="supplier_name">
          <span v-for="item in supplierList" v-show="item.id==row.supplier_id">{{ item.client_name }}</span>
        </template>
        <template slot-scope="{ row }" slot="created_at">
          <span>{{ row.created_at.substring(0, 10) }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <Button v-show="row.status==2" type="text" size="small" style="color:#19be6b" @click="checkOrderInfo(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>

          <Button v-show="row.status==1" type="text" size="small" :style="getStatusColor(row.status)"  @click="checkOrderInfo(row)">
            <Icon type="ios-checkmark-circle"/>
            审核
          </Button>

          <Dropdown v-show="[1].includes(row.status)">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-show="row.status==1">
                <span @click="listEdit(row)" class="f12">修改</span>
              </DropdownItem>
              <DropdownItem v-show="row.status==1">
                <span @click="listDeleteAlert(row)" class="f12">删除</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>

    </div>

    <!--添加修改采购订单-->
    <Modal v-model="add_edit.modal" :title="add_edit.model_title" width="1280">
      <div class="modalForm">
        <Form ref="clientFormItem" :model="clientFormItem" :label-width="100" :rules="ruleInline">
          <Row :gutter="20">
            <Col span="8">
              <FormItem label="退货单号" prop="main_no">
                <Input v-model="clientFormItem.main_no" disabled placeholder="请填写退货单号"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="供应商" prop="supplier_id">
                <Select v-model="clientFormItem.supplier_id" placeholder="选择供应商"
                        @on-change="getSupplierList(clientFormItem.supplier_id)">
                  <Option v-for="item in supplierList" :value="item.id">{{ item.client_name }}
                    ({{ item.contact_person }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="2">&nbsp;</Col>
            <Col span="11">
              <FormItem label="收货地址" prop="address">
                <Input v-model="clientFormItem.address" placeholder="请填写供应商收货地址"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="物流" prop="logistics_id">
                <Select v-model="clientFormItem.logistics_id" placeholder="请选择物流">
                  <Option v-for="item in logisticsList" :value="item.id">{{ item.client_name }}
                    ({{ item.contact_person }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="2">&nbsp;</Col>
            <Col span="11">
              <FormItem label="物流单号" prop="logistics_order_id">
                <Input v-model="clientFormItem.logistics_order_id" placeholder="请填写物流单号"
                       maxlength="300"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Button icon="md-add" type="primary" @click="addGoods()">添加货品</Button>
            </Col>
          </Row>
          <Row class="mt2 display-block">
            <Table :columns="table_columns_modal" :data="clientFormItem.goods_setted" border show-summary
                   :summary-method="addGoodsHandleSummary">
              <template slot-scope="{ row }" slot="thumbnail_url">
                <p><img :src="row.thumbnail_url" alt="" class="preview-img" v-show="row.thumbnail_url"></p>
              </template>
              <template slot-scope="{ row, index }" slot="goods_num">
                <p>
                  <i-input type="number" v-model="row.goods_num" @on-change="priceSum(row, index)"/>
                </p>
              </template>
              <template slot-scope="{ row, index }" slot="goods_price">
                <p>
                  <i-input type="number" v-model="row.goods_price" @on-change="priceSum(row, index)"/>
                </p>
              </template>
              <template slot-scope="{ row, index }" slot="action">
                <Button type="text" size="small" @click="deleteGoodsSelected(row, index)">
                  <Icon type="ios-trash"/>
                  删除
                </Button>
              </template>
            </Table>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset(clientFormItem)">取消</Button>
        <Button type="primary" :loading="modal_loading.add_edit" @click="createBuyOrder('clientFormItem')">保存</Button>
      </div>
    </Modal>

    <!--审核-->
    <Modal v-model="check_order.modal" :title="check_order.title" width="1280">
      <div class="modalForm">
        <Form ref="clientFormItem" :model="clientFormItem" :label-width="100" :rules="ruleInline">
          <Row :gutter="20">
            <Col span="8">
              <FormItem label="退货单号:" prop="main_no">
                <span>{{ clientFormItem.main_no }}</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="供应商:" prop="supplier_id">
                <span v-for="item in supplierList" v-show="item.id==clientFormItem.supplier_id">{{ item.client_name }} ({{ item.contact_person }})</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="退货理由:" prop="return_reason">
                <span>{{ clientFormItem.return_reason }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="8">
              <FormItem label="收货地址:" prop="address">
                <span>{{ clientFormItem.address }}</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="物流:" prop="logistics_id">
                <span v-for="item in logisticsList" v-show="item.id==clientFormItem.logistics_id">{{ item.client_name }} ({{ item.contact_person }})</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="物流单号:" prop="logistics_order_id">
                <span>{{ clientFormItem.logistics_order_id }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="8">
              <FormItem label="关联单号" prop="buy_no">
                <span class="pointer data-copy" @click="copyData(clientFormItem.buy_no)">{{clientFormItem.buy_no}}<Icon type="ios-copy-outline"/></span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="退货金额:" prop="goods_pay">
                <strong class="main-font-color">{{clientFormItem.goods_pay}}</strong>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="实退金额:" prop="real_pay">
                <strong class="main-font-color">{{clientFormItem.real_pay}}</strong>
              </FormItem>
            </Col>
          </Row>

          <Row :gutter="20">
            <Col span="8">
              <FormItem label="单据状态:" prop="status">
                <Tag :style="getStatusColorTg(clientFormItem.status)" class="white-font-color">{{ getStatusText(clientFormItem.status) }}</Tag>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="退款状态:" prop="pay_status">
                <span :style="getPayStatusColor(clientFormItem.pay_status)">{{ getPayStatusText(clientFormItem.pay_status) }}</span>
                <Button type="text" @click="setPayStatusFinish(clientFormItem)" class="status-main fs-12" v-if="clientFormItem.pay_status == 2 && ![1,5].includes(clientFormItem.status)">
                  <Icon type="ios-checkmark-circle"/>
                  设为完结状态
                </Button>
              </FormItem>
            </Col>
          </Row>

          <Row class="display-block">
            <Card dis-hover >
              <div>
                <span style="margin:0 10px" :class="common.view_flag == 1 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(1)">退货明细</span>
                <span style="margin:0 10px" :class="common.view_flag == 2 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(2)">原采购明细</span>
                <span style="margin:0 10px" :class="common.view_flag == 3 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(3)">退款金额记录</span>
                <Divider/>
              </div>
              <div v-show="common.view_flag == 1">
                <Table :columns="table_columns_modal" :data="clientFormItem.goods_setted" show-summary
                       :summary-method="addGoodsHandleSummary">
                  <template slot-scope="{ row }" slot="thumbnail_url">
                    <p><img :src="row.thumbnail_url" alt="" class="preview-img" v-show="row.thumbnail_url"></p>
                  </template>
                  <template slot-scope="{ row, index }" slot="goods_num">
                    <span>{{ row.goods_num }}</span>
                  </template>
                  <template slot-scope="{ row, index }" slot="goods_price">
                    <span>{{ row.goods_price }}</span>
                  </template>
                  <template slot-scope="{ row, index }" slot="action">
                    <span class="disabled-color fs-12">------</span>
                  </template>
                </Table>
              </div>
              <div v-show="common.view_flag == 2">
                <Table :columns="table_columns_modal" :data="clientFormItem.buy_goods_set" show-summary
                       :summary-method="addGoodsHandleSummary">
                  <template slot-scope="{ row }" slot="thumbnail_url">
                    <p><img :src="row.thumbnail_url" alt="" class="preview-img" v-show="row.thumbnail_url"></p>
                  </template>
                  <template slot-scope="{ row, index }" slot="goods_num">
                    <span>{{ row.goods_num }}</span>
                  </template>
                  <template slot-scope="{ row, index }" slot="goods_price">
                    <span>{{ row.goods_price }}</span>
                  </template>
                  <template slot-scope="{ row, index }" slot="action">
                    <span class="disabled-color fs-12">------</span>
                  </template>
                </Table>
              </div>
              <div v-show="common.view_flag == 3">
                <Table :columns="common.real_pay_columns" :data="clientFormItem.flow_info">
                  <template slot-scope="{ row, index }" slot="money">
                    <strong>{{ row.money }}</strong>
                  </template>
                </Table>
              </div>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetOrderModal(clientFormItem)">关闭</Button>
        <Button type="primary" v-show="clientFormItem.status==1" @click="showEditBuyOrder(clientFormItem)">修改订单</Button>
        <Button type="success" v-show="clientFormItem.status==1" :loading="modal_loading.success"
                @click="passBuyOrder(clientFormItem)">审核通过
        </Button>

        <Button type="warning" v-show="[1,2].includes(clientFormItem.pay_status)"
                @click="payBuyReturnOrder(clientFormItem)" v-if="aCheck.rightCheck('valid_procurement_returns')">退款
        </Button>

        <Button type="info" v-show="clientFormItem.status==3" :loading="modal_loading.success" v-if="aCheck.rightCheck('valid_procurement_returns')"
                @click="finishBuyOrder(clientFormItem)">确认收货
        </Button>
      </div>
    </Modal>

    <!--退货退款-->
    <Modal v-model="pay_return_buy.modal" title="退款操作" width="800">
      <div class="modalForm">
        <Form ref="pay_return_buy_item" :model="pay_return_buy.item" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="订单金额" prop="goods_pay">
                <strong>{{pay_return_buy.item.goods_pay}}</strong>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="已收金额" prop="goods_pay">
                <span class="main-font-color">{{pay_return_buy.item.real_pay}}</span>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="退款金额" prop="current_pay">
                <Input v-model="pay_return_buy.item.current_pay" placeholder="请填写退款金额" type="number"></Input>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="退款时间" prop="pay_time">
                <DatePicker :value="pay_return_buy.item.pay_time" type="datetime"
                            format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请填写退款时间(不填，默认当前时间)" style="width: 275px"
                            @on-change="getBuyPayDateTime"></DatePicker>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="备注" prop="remark">
                <Input v-model="pay_return_buy.item.remark" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="closePayBuy">关闭</Button>
        <Button type="primary" :loading="pay_return_buy.modal_loading" @click="payReturnBuyOperate(pay_return_buy.item)">确认退款</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import * as supplier_utils from '@/utils/supplier';
import * as logistics_util from '@/utils/logistics';
import * as copy_util from '@/utils/copy';
import NP from 'number-precision';
import {apiPayReturnBuyOperate, apiSetBuyReturnPayStatusEnd} from "@/api/buy/buy";

export default {
  name: "BuyReturns",
  data() {
    return {
      common: {
        view_flag: 1,
        real_pay_columns: [
          {
            title: '流水单号',
            key: 'flow_id',
            align: 'center',
          },
          {
            title: '退款金额',
            key: 'money',
            slot: 'money',
            align: 'center',
          },
          {
            title: '退款时间',
            key: 'flow_time',
            align: 'center',
          },
          {
            title: '备注',
            key: 'desc',
            align: 'center',
          },
          {
            title: '操作人员',
            key: 'operator_name',
            align: 'center',
          },
        ]
      },
      pay_return_buy: {
        modal:false,
        modal_loading: false,
        item: {
          goods_pay: '',
          real_pay: '',
          current_pay: '',
          pay_time: '',
          remark:''
        }
      },
      formItem: {
        main_no: '',
        status: '',
        created_at: '',
        buy_no: '',
      },
      clientFormItem: {
        id: '',
        main_no: '',
        buy_type: '',
        supplier_id: '',
        logistics_id: '',
        logistics_order_id: '',
        address: '',
        goods_setted: [],
        flow_id: '',
        buy_no: '',
        buy_goods_set: [],
        pay_status: '',
        real_pay: '',
        flow_info:[],
      },

      check_order: {
        title: '审核订单信息',
        modal: false,
      },

      //创建
      add_edit: {
        modal: false,
        model_title: '创建采购单信息',
      },

      modal_loading: {
        success: false,
        add_edit: false,
      },

      //列表
      list: {
        page_size_opts: [10, 15, 20, 30],
        page_size_key: 'buy_returns_page_size_key',
        columns: [
          {
            title: '退货单号',
            key: 'main_no',
            slot: 'main_no',
            width: 130,
            align: 'center',
          },
          {
            title: '单号状态',
            key: 'status',
            slot: 'status',
            width: 100,
            align: 'center',
          },
          {
            title: '金额',
            key: 'goods_pay',
            // width:120,
            align: 'center',
          },
          {
            title: '实退金额',
            key: 'real_pay',
            align: 'center',
          },
          {
            title: '退款状态',
            key: 'pay_status',
            slot: 'pay_status',
            align: 'center',
          },
          {
            title: '供应商',
            key: 'supplier_name',
            slot: 'supplier_name',
            align: 'center',
          },
          {
            title: '关联单号',
            key: 'buy_no',
            slot: 'buy_no',
            width: 150,
            align: 'center',
          },
          {
            title: '创建时间',
            slot: 'created_at',
            key: 'created_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
          }
        ],
        data: [],
        total: 0,
        size: 0,
        page: 1,
      },
      ruleInline: {
        // buy_type: [
        // 	{required: true, message: '请选择结算方式', trigger: 'change'}
        // ],
        // supplier_id: [
        // 	{required: true, message: '请选择供应商', trigger: 'change'},
        // ],
        // logistics_id: [
        // 	{required: true, message: '请选择物流', trigger: 'change'},
        // ],
        // address: [
        // 	{required: true, message: '请选择收货地址', trigger: 'change'},
        // ],
      },
      file: null,
      logisticsList: [],
      supplierList: [],
      imgUrl: '',
      table_columns_modal: [
        {
          title: '货品编号',
          key: 'part_no',
          align: 'center',
        },
        {
          title: '货品名称',
          align: 'center',
          key: 'part_name'
        },
        {
          title: '缩略图',
          key: 'thumbnail_url',
          align: 'center',
          slot: 'thumbnail_url'
        },
        {
          title: '退货数量',
          key: 'goods_num',
          align: 'center',
          slot: 'goods_num'
        },
        {
          title: '退货单价',
          key: 'goods_price',
          align: 'center',
          slot: 'goods_price'
        },
        {
          title: '金额',
          align: 'center',
          key: 'goods_money',
        },
        {
          title: '仓库库存',
          align: 'center',
          key: 'stock_all'
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          slot: 'action'
        }
      ],
      view: {
        buy_flag: false,
      },

      buy_type_info: [
        {
          "id": 1,
          "name": "现金"
        },
        {
          "id": 2,
          "name": "记账"
        }
      ],
    };
  },
  methods: {
    setPayStatusFinish(item, show_type='view') {
      if (item.pay_status != 2 || [1,5].includes(item.status)) {
        return;
      }
      this.$Modal.confirm({
        title: "订单退款金额状态完结操作",
        content: '计划退款金额为: <strong class="status-main">'+item.goods_pay.toString()
            +'</strong><br/> 实际退款金额为: <strong class="status-main">'+item.real_pay.toString()+'</strong><br/>'
            + '确定将订单状态设置为<span class="status-danger">退款完结</span>状态？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            main_no: item.main_no,
            link_id: item.id
          }
          apiSetBuyReturnPayStatusEnd(param).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              if (show_type == 'view') {
                this.checkOrderInfo(item);
              }
              this.getOrderList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    selectTap(flag) {
      this.common.view_flag = flag;
    },
    closePayBuy() {
      this.pay_return_buy.modal = false;
    },
    payReturnBuyOperate(row) {
      this.pay_return_buy.modal_loading = true;
      if (row.current_pay ==0 || row.current_pay<0) {
        this.pay_return_buy.modal_loading = false;
        this.$Message.error('退款金额不能为0或负数！');
        return false;
      }

      this.$Modal.confirm({
        title: "退款确认提示",
        content: '退款操作即将执行，请确认填写无误？当退款金额加上收金额大于等于订单金额时，订单将自动完结！',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            link_id : row.id,
            pay_money: row.current_pay,
            pay_time: row.pay_time,
            remark: row.remark,
          }
          apiPayReturnBuyOperate(param).then(res => {
            if (res.data.err_no == 0) {
              this.pay_return_buy.modal_loading = false;
              this.$Message.success(res.data.results);
              this.pay_return_buy.modal = false;
              this.getBuyReturnOrderById(row.id);
              this.getOrderList();
            } else {
              this.pay_return_buy.modal_loading = false;
              this.$Message.error(res.data.err_msg);
            }
          }).catch( error => {
            this.pay_return_buy.modal_loading = false;
          })
        },
        onCancel: () => {
          this.pay_return_buy.modal_loading = false;
        }
      });
    },
    getBuyPayDateTime(value, type) {
      this.pay_return_buy.item.pay_time = value;
    },
    payBuyReturnOrder(row) {
      this.pay_return_buy.item.id = row.id;
      this.pay_return_buy.item.goods_pay = row.goods_pay;
      this.pay_return_buy.item.real_pay = row.real_pay;
      this.pay_return_buy.item.current_pay = NP.minus(row.goods_pay, row.real_pay);
      this.pay_return_buy.item.remark = '';
      this.pay_return_buy.item.pay_time = '';
      this.pay_return_buy.modal = true;
    },
    copyData(data) {
      copy_util.copyData(data, this.$Message);
    },
    getStatusColorTg(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'background-color:#ed4014';
          break;
        case 2:
          rs = 'background-color:#19be6b';
          break;
        case 3:
          rs = 'background-color:#2db7f5';
          break;
        case 4:
          rs = 'background-color:#2775ff';
          break;
        case 5:
          rs = 'background-color:#c5c8ce';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'color:#ed4014';
          break;
        case 2:
          rs = 'color:#19be6b';
          break;
        case 3:
          rs = 'color:#2db7f5';
          break;
        case 4:
          rs = 'color:#2775ff';
          break;
        case 5:
          rs = 'color:#c5c8ce';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待审核';
          break;
        case 2:
          rs = '已退货';
          break;
        case 3:
          rs = '已出库';
          break;
        case 4:
          rs = '已完成';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getSupplierList() {
      supplier_utils.getSupplierAll().then(res => {
        this.supplierList = res;
      });
    },

    //求总价
    priceSum(row, index) {
      if (isNaN(row.goods_num) || isNaN(row.goods_price)) {
        this.clientFormItem.goods_setted[index].goods_money = 0;
      } else {
        //赋值
        this.clientFormItem.goods_setted[index].goods_num = row.goods_num;
        this.clientFormItem.goods_setted[index].goods_price = row.goods_price;
        row.buy_money = NP.times(row.buy_num, row.buy_price);
        this.clientFormItem.goods_setted[index].goods_money = row.goods_money;
      }
    },

    // 去除已选择的货品
    deleteGoodsSelected(row, index) {
      this.clientFormItem.goods_setted.splice(index, 1);
    },

    //表格数据汇总
    addGoodsHandleSummary({columns, data}) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: '总计'
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (!values.every(value => isNaN(value)) && (index == 3 || index == 5)) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return NP.plus(prev, curr);
            } else {
              return prev;
            }
          }, 0);

          if (index == 3) {
            sums[key] = {
              key,
              value: '共 ' + v
            };
          } else if (index == 5) {
            sums[key] = {
              key,
              value: '共 ' + v
            };
          }

        } else {
          sums[key] = {
            key,
            value: ''
          };
        }
      });

      return sums;
    },


    handleReset(name) {
      //弹出框重置
      this.add_edit.modal = false;
      this.modal_loading.add_edit = false;
    },

    // create(name) {
    //   //创建货品信息
    //   this.modal_loading.add_edit = false;
    //   this.imgUrl = '';
    //   this.add_edit.modal = true;
    //   this.modal_loading.add_edit = false;
    //   this.$refs[name].resetFields();
    //
    //   this.$axios.get('/api/buy/getBuyId').then((response) => {
    //         if (response.data.err_no == 0) {
    //           this.clientFormItem.main_no = response.data.results;
    //           this.clientFormItem.goods_setted = [];
    //         }
    //       });
    // },

    listEdit(row) {
      this.getBuyReturnOrderById(row.id);

      this.imgUrl = row.image_url ? row.image_url : '';

      this.add_edit.model_title = '修改采购退货单信息';
      this.add_edit.modal = true;
      this.modal_loading.add_edit = false;
    },

    //获取采购退货单信息
    getBuyReturnOrderById(id) {
      this.$axios.get('/api/buy/getBuyReturnOrderById?id=' + id).then((res) => {
        if (res.data.err_no == 0) {
          let result = res.data.results;
          this.clientFormItem.id = result.id;
          this.clientFormItem.main_no = result.main_no;
          this.clientFormItem.status = result.status;
          this.clientFormItem.goods_num = parseFloat(result.goods_num);
          this.clientFormItem.goods_pay = parseFloat(result.goods_pay);
          this.clientFormItem.real_pay = parseFloat(result.real_pay);
          this.clientFormItem.pay_status = result.pay_status;
          this.clientFormItem.buy_no = result.buy_no;
          this.clientFormItem.supplier_id = result.supplier_id;
          this.clientFormItem.logistics_id = result.logistics_id;
          this.clientFormItem.logistics_order_id = result.logistics_order_id;
          this.clientFormItem.address = result.address;
          this.clientFormItem.return_reason = result.return_reason;


          this.clientFormItem.buy_goods_set = result.buy_goods_set !== undefined ? result.buy_goods_set : [];
          for (let i=0; i<this.clientFormItem.buy_goods_set.length; i++) {
            this.clientFormItem.buy_goods_set[i].goods_money = parseFloat(this.clientFormItem.buy_goods_set[i].goods_money);
            this.clientFormItem.buy_goods_set[i].goods_num = parseFloat(this.clientFormItem.buy_goods_set[i].goods_num);
            this.clientFormItem.buy_goods_set[i].goods_price = parseFloat(this.clientFormItem.buy_goods_set[i].goods_price);
            this.clientFormItem.buy_goods_set[i].stock_all = parseFloat(this.clientFormItem.buy_goods_set[i].stock_all);
          }
          for (let i=0; i<result.goods_setted.length; i++) {
            result.goods_setted[i].goods_money = parseFloat(result.goods_setted[i].goods_money);
            result.goods_setted[i].goods_num = parseFloat(result.goods_setted[i].goods_num);
            result.goods_setted[i].goods_price = parseFloat(result.goods_setted[i].goods_price);
            result.goods_setted[i].stock_all = parseFloat(result.goods_setted[i].stock_all);
          }

          this.clientFormItem.goods_setted = result.goods_setted;

          for (let i=0; i<result.flow_info.length; i++) {
            result.flow_info[i].money = parseFloat(result.flow_info[i].money);
          }
          this.clientFormItem.flow_info = result.flow_info;
        }
      });
    },

    //删除操作提示
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除采购订单信息",
        content: '确定删除采购订单信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row.id);
        },
        onCancel: () => {
        }
      });
    },

    //取消订单
    cancelBuyOrder(row) {
      this.$Modal.confirm({
        title: "取消采购订单信息",
        content: '确定取消采购订单信息？一旦取消订单，该订单将失效，不再有效。',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.$axios.post('/api/buy/cancelBuyOrder', {id: row.id}).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getOrderList();
              this.check_order.modal = false;
            } else {
              this.$Message.error(response.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },

    //删除订单
    deleteListInfo($id) {
      this.$axios.post('/api/buy/deleteBuyReturnListInfo', {return_id: $id}).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getOrderList();
          this.check_order.modal = false;
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },
    getPayStatusColor(index) {
      let rs = '';
      switch (index) {
        case 2:
          rs = 'color:#ff9900';
          break;
        case 3:
        case 4:
          rs = 'color:#2775ff';
          break;
        case 1:
          rs = 'color:#ed4014';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getPayStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待退款';
          break;
        case 2:
          rs = '有退款';
          break;
        case 3:
          rs = '退款完结';
          break;
        case 4:
          rs = '超额退款';
          break;
        case 5:
          rs = '无需退款';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },

    //创建采购订单
    createBuyOrder(name) {
      this.modal_loading.add_edit = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          //判断是否添加了采购货品
          let goods_setted = [];
          if (this.clientFormItem.goods_setted.length < 1) {
            this.$Message.error('请添加采购货品');
            this.modal_loading.add_edit = false;
            return;
          } else {
            //过滤无效数据
            for (let i = 0; i < this.clientFormItem.goods_setted.length; i++) {
              let tmp = {
                goods_id: '',
                goods_num: 0,
                goods_price: 0,
              };
              tmp.goods_id = this.clientFormItem.goods_setted[i].id;
              tmp.goods_num = this.clientFormItem.goods_setted[i].goods_num;
              tmp.goods_price = this.clientFormItem.goods_setted[i].goods_price;

              if (tmp.goods_num <= 0) {
                this.$Message.error('采购货品数量不能小于等于0');
                this.modal_loading.add_edit = false;
                return;
              }

              if (tmp.goods_price <= 0) {
                this.$Message.error('采购货品单价不能小于等于0');
                this.modal_loading.add_edit = false;
                return;
              }

              if (tmp.goods_num > this.clientFormItem.goods_setted[i].stock_all) {
                this.$Message.error('采购货品数量不能大于库存');
                this.modal_loading.add_edit = false;
                return;
              }

              goods_setted[i] = tmp;
            }
          }

          let param = {
            id: this.clientFormItem.id,
            main_no: this.clientFormItem.main_no,
            buy_type: this.clientFormItem.buy_type,
            supplier_id: this.clientFormItem.supplier_id,
            logistics_id: this.clientFormItem.logistics_id,
            logistics_order_id: this.clientFormItem.logistics_order_id,
            address: this.clientFormItem.address,
            flow_id: this.clientFormItem.flow_id,
            goods_setted: goods_setted,
          };
          this.$axios.post('/api/buy/createBuyOrder', param).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getOrderList();
              this.add_edit.modal = false;
            } else {
              this.$Message.error(response.data.err_msg);
              this.modal_loading.add_edit = false;
            }
          });
        } else {
          this.modal_loading.add_edit = false;
        }
      });
    },
    searchSubmit() {
      this.list.page = 1;
      this.getOrderList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getOrderList();
    },
    //获取货品列表
    getOrderList() {
      let param = {
          page: this.list.page,
          size: this.list.size,
          main_no: this.formItem.main_no,
          buy_no: this.formItem.buy_no,
          status: this.formItem.status,
          created_at: this.formItem.created_at,
      };
      this.$axios.post('/api/buy/getBuyReturnList', param).then((res) => {
        if (res.data.err_no == 0) {
          for (let i=0; i<res.data.results.list.length; i++) {
            res.data.results.list[i].real_pay = parseFloat(res.data.results.list[i].real_pay);
            res.data.results.list[i].goods_pay = parseFloat(res.data.results.list[i].goods_pay);
            res.data.results.list[i].goods_num = parseFloat(res.data.results.list[i].goods_num);
          }
          this.list.data = res.data.results.list;
          this.list.total = res.data.results.total;
          this.list.size = res.data.results.size;
        }
      });
    },
    changePage(page) {
      this.list.page = page
      this.getOrderList();
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getOrderList();
    },
    getLogisticsList() {
      logistics_util.getLogisticsAll().then(res => {
        this.logisticsList = res;
      });
    },

    //打开审核页面
    checkOrderInfo(row) {
      //待审核状态时，显示标题为 审核订单信息
      if (row.status != 1) {
        //非审核状态时,标题为 查看订单信息
        this.check_order.title = '查看订单信息';
      }

      this.getBuyReturnOrderById(row.id);

      this.imgUrl = row.image_url ? row.image_url : '';

      this.check_order.modal = true;
    },
    //显示订单信息
    showEditBuyOrder(clientFormItem) {
      this.listEdit(clientFormItem);
      this.check_order.modal = false;
    },
    //采购订单审核通过
    passBuyOrder(clientFormItem) {
      this.modal_loading.success = true;

      let param = {
        id: clientFormItem.id,
      };

      this.$axios.post('/api/buy/passBuyOrder', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getOrderList();
              this.check_order.modal = false;
            } else {
              this.$Message.error(response.data.err_msg);
            }
            this.modal_loading.success = false;
          });
    },

    //订单完成
    finishBuyOrder(clientFormItem) {
      this.modal_loading.success = true;

      let param = {
        id: clientFormItem.id,
      };

      this.$axios.post('/api/buy/finishBuyOrder', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getOrderList();
              this.check_order.modal = false;
            } else {
              this.$Message.error(response.data.err_msg);
            }
            this.modal_loading.success = false;
          });
    },

    //审核弹出框重置
    handleResetOrderModal(name) {
      this.check_order.modal = false;
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.formItem.created_at = dateRange;
      } else {
        this.formItem.created_at = "";
      }

      this.searchSubmit();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },

  },
  mounted() {
    this.setSelectedPageSize();
    this.getOrderList();

    this.getLogisticsList();
    this.getSupplierList();
  },
  created() {
    this.aCheck.plateName = 'buy_manage';
  },
};
</script>

<style scoped>
.select-flag {
  font-size: 16px;
  font-weight: 500;
}
#clientInfo {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}
</style>
<style>
#clientInfo .ivu-card {
  background-color: #fafafa;
}

#clientInfo .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#clientInfo .ivu-form-item {
  margin-bottom: 0;
}

#list .ivu-btn-small {
  font-size: 12px !important;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

.buy_returns .ivu-table-overflowX, .buy_returns .ivu-table-tip {
  overflow-x: hidden;
}

.buy_returns .ivu-table-wrapper {
  overflow: visible;
}

.ivu-divider-horizontal {
  margin: 10px 0 !important;
}
</style>
