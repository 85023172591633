<template id="menu">
  <Menu theme="light" :active-name="active_name" @on-select="onselect" width="auto">
    <MenuGroup title="采购管理" v-if="aCheck.moduleCheck('buy_module')">
      <MenuItem name="1" v-if="aCheck.pageCheck('procurement_list')">
        <Icon type="ios-basket"/>
        采购列表
      </MenuItem>
      <!--<MenuItem name="2">-->
      <!--<Icon type="ios-paper" />-->
      <!--缺货登记-->
      <!--</MenuItem>-->
      <MenuItem name="3" v-if="aCheck.pageCheck('procurement_returns')">
        <Icon type="logo-dropbox"/>
        采购退货
      </MenuItem>
    </MenuGroup>
  </Menu>
</template>

<script>
import * as loginUtil from '@/utils/login'

export default {
  name: "BuyManageMenu",
  data() {
    return {
      active_name: this.active,
      menu_name: this.menuName,
    };
  },
  methods: {
    onselect(name) {
      this.$emit('func', name);
      localStorage.setItem(this.menu_name, name);
      loginUtil.setExpireTime();
    },
    setNavInfo(name) {
      this.active_name = name.toString()
    }
  },
  created() {
    this.aCheck.plateName = 'buy_manage';
  },
  props: ["active", "menuName"],
};
</script>

<style scoped>
#menu {
  width: 200px;
  position: absolute;
  left: 0;
}
</style>
