<template>
  <div id="clientInfo">
    <div id="panel-header">
      <span class="top-title">缺货登记列表</span>
      <Button
          type="primary"
          class="client_create"
          @click="create('clientFormItem')">添加缺货登记
      </Button>
    </div>
    <!--列表筛选条件-->
    <div id="filter-bar">
      <Card style="width:100%" dis-hover>
        <Form :model="formItem" :label-width="80" inline>
          <Row>
            <Col span="6">
              <FormItem label="登记编号">
                <i-input
                    v-model="formItem.main_no"
                    size="small"
                    placeholder="请填写缺货登记编号"/>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="登记货品">
                <Select
                    v-model="formItem.goods_ids"
                    size="small"
                    placeholder="请选择货品"
                    multiple
                    filterable>
                  <Option
                      v-for="item in goodsList"
                      :value="item.id"
                      :key="item.id">{{ item.part_name }}
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="登记状态">
                <Select
                    v-model="formItem.status"
                    size="small"
                    placeholder="请选择缺货登记状态"
                    filterable>
                  <Option
                      v-for="item in allLackRecordStatus"
                      :value="item.id"
                      :key="item.id">{{ item.name }}
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem :label-width="20">
                <Button
                    type="primary"
                    size="small"
                    @click="searchSubmit()"
                    shape="circle"
                    icon="ios-search">搜索
                </Button>&nbsp;&nbsp;
                <Button
                    type="default"
                    size="small"
                    @click="cancelSearch()"
                    shape="circle">重置
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>

    <!--列表-->
    <div id="list" class="stock_lack_list">
      <Table :columns="columnsInfo" :data="listInfo">
        <template slot-scope="{ row }" slot="main_no">
          <strong>{{ row.main_no }}</strong>
        </template>

        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)">{{ getStatusText(row.status) }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <Button v-show="row.status==2" type="text" size="small" :style="getStatusColor(row.status)" @click="checkOrderInfo(row)">
            <Icon type="ios-checkmark-circle"/>
            处理完成
          </Button>

          <span v-show="row.status==3" :style="getStatusColor(row.status)">------</span>

          <Dropdown v-show="row.status!=3">
            <Button type="text" size="small" @click="listEdit(row)">
              <Icon type="ios-create"/>
              修改
            </Button>
            <Button type="text" size="small" @click="listDeleteAlert(row)">
              <Icon type="ios-trash"/>
              删除
            </Button>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="listTotal" :page-size="pageSize" show-total show-elevator @on-change="changePage"/>
      </div>

    </div>

    <!--添加修改缺货登记订单-->
    <Modal v-model="modal1" :title="model_title" width="960">
      <div class="modalForm">
        <Form ref="clientFormItem" :model="clientFormItem" :label-width="100">
          <Row>
            <Col span="11">
              <FormItem label="缺货登记单号" prop="main_no">
                <Input v-model="clientFormItem.main_no" disabled placeholder="请填写缺货登记单号"></Input>
              </FormItem>
            </Col>
            <Col span="2">&nbsp;</Col>
            <Col span="11">
              <FormItem label="货品" prop="goods_id">
                <Select v-model="clientFormItem.goods_id" placeholder="请选择货品" filterable>
                  <Option v-for="item in goodsList" :value="item.id">{{ item.part_name }}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col span="11">
              <FormItem label="缺货数量" prop="num">
                <Input v-model="clientFormItem.num" placeholder="请填写缺货数量" maxlength="300"></Input>
              </FormItem>
            </Col>

          </Row>

        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset(clientFormItem)">取消</Button>
        <Button type="primary" :loading="modal_loading.add_edit" @click="createLackRecord('clientFormItem')">保存
        </Button>
      </div>
    </Modal>


  </div>
</template>

<script>
export default {
  name: "StockLackList",
  data() {
    return {
      formItem: {
        main_no: '',
        status: '',
        goods_ids: []
      },

      allLackRecordStatus: [
        {
          "id": 1,
          "name": '缺货'
        },
        {
          "id": 2,
          "name": '补货中，生成采购单'
        },
        {
          "id": 3,
          "name": '补货完成'
        },

      ],
      clientFormItem: {
        id: '',
        main_no: '',
        goods_id: '',
        num: '',
        status: '',
      },
      model_title: '创建缺货信息',
      modal1: false,
      modal_loading: {
        success: false,
        add_edit: false,
      },
      columnsInfo: [
        {
          title: '登记单号',
          key: 'main_no',
          slot: 'main_no',
          align: 'center',
          minWidth: 20,
        },
        {
          title: '操作员',
          key: 'operator_name',
          align: 'center',
        },
        {
          title: '货品id',
          key: 'goods_id',
          align: 'center',
        },
        {
          title: '货品名称',
          key: 'goods_name',

        },
        {
          title: '登记单状态',
          key: 'status',
          slot: 'status',
          align: 'center',
        },
        {
          title: '缺货数量',
          key: 'num',
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'created_at',
          align: 'center',
        },
        {
          title: '修改时间',
          key: 'updated_at',
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          slot: 'action',
          minWidth: 100,
        }
      ],
      listInfo: [],

      listTotal: 0,
      pageSize: 0,
      searchFlag: 0,
      goodsList: [
        {
          id: '',
          part_no: '',
          part_name: ''
        }
      ],
    };
  },
  methods: {
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'color:#ed4014';
          break;
        case 2:
          rs = 'color:#19be6b';
          break;
        case 3:
          rs = 'color:#2db7f5';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusText(index) {
      let rs = '';

      switch (index) {
        case 1:
          rs = '缺货';
          break;
        case 2:
          rs = '补货中，生成采购单';
          break;
        case 3:
          rs = '补货完成';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },

    getGoodsList() {
      //获取货品列表

      this.$axios.get('/api/goods/getGoodsAll')
          .then((response) => {
            if (response.data.err_no == 0) {
              this.goodsList = response.data.results;
            }
          });
    },

    handleReset(name) {
      //弹出框重置
      this.modal1 = false;
      this.modal_loading.add_edit = false;
    },
    create(name) {
      //创建登记单信息
      this.modal_loading.add_edit = false;
      this.model_title = '创建缺货登记单信息';
      this.imgUrl = '';
      this.modal1 = true;
      this.$refs[name].resetFields();
      this.$axios.get('/api/buy/getLackId')
          .then((response) => {
            if (response.data.err_no == 0) {
              this.clientFormItem.main_no = response.data.results;
            }
          });
    },

    listEdit(row) {

      this.model_title = '修改缺货登记订单信息';
      this.modal1 = true;

      this.clientFormItem.id = row.id;
      this.clientFormItem.main_no = row.main_no;
      this.clientFormItem.status = row.status;
      this.clientFormItem.goods_id = row.goods_id;
      this.clientFormItem.num = row.num;
    },

    //删除操作提示
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除缺货登记订单信息",
        content: '确定删除缺货登记订单信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteLackRecord(row.id);
        },
        onCancel: () => {
        }
      });
    },

    deleteLackRecord($id) {
      let param = {
        id: $id,
      };
      this.$axios.post('/api/buy/deleteLackRecord', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getStockLackRecordList();
            } else {
              this.$Message.error(response.data.err_msg);
            }
          });
    },
    createLackRecord(name) {
      //创建缺货登记订单
      this.modal_loading.add_edit = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          let param = {
            id: this.clientFormItem.id,
            main_no: this.clientFormItem.main_no,
            goods_id: this.clientFormItem.goods_id,
            num: this.clientFormItem.num,
            status: this.clientFormItem.status
          };
          this.$axios.post('/api/buy/createLackRecord', param)
              .then((response) => {
                if (response.data.err_no == 0) {
                  this.$Message.success(response.data.results);
                  this.getStockLackRecordList();
                  this.modal1 = false;
                } else {
                  this.$Message.error(response.data.err_msg);
                }
                this.modal_loading.add_edit = false;
              });
        } else {
          this.modal_loading.add_edit = false;
        }
      });
    },

    searchSubmit() {
      this.searchFlag = 1;
      this.getStockLackRecordList();
    },
    cancelSearch() {
      this.searchFlag = 0;
      this.formItem.main_no = '';
      this.formItem.status = '';
      this.formItem.goods_ids = [];
      this.getStockLackRecordList();
    },
    getStockLackRecordList($page = 1, $main_no = '', $status = '', $goods_ids = []) {
      //获取货品列表
      if (this.searchFlag) {
        $main_no = this.formItem.main_no;
        $status = this.formItem.status;
        $goods_ids = this.formItem.goods_ids;
      }
      let param = {
        params: {
          page: $page,
          main_no: $main_no,
          status: $status,
          goods_ids: $goods_ids,
        }
      };
      this.$axios.get('/api/buy/getStockLackRecordList', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.listInfo = response.data.results.list;
              this.listTotal = response.data.results.total;
              this.pageSize = response.data.results.size;
            }
          });
    },
    changePage(page) {
      this.getStockLackRecordList(page);
    },


  },
  mounted() {
    this.getStockLackRecordList();
    this.getGoodsList();

  },
};
</script>

<style scoped>
#clientInfo {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

.fleft {
  float: left;
}

.ml10 {
  margin-left: 10px;
}

.f12 {
  font-size: 12px !important;
}

#panel-header {
  position: relative;
  border: none;
}

.modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}
</style>
<style>
#clientInfo .ivu-card {
  background-color: #fafafa;
}

#clientInfo .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#clientInfo .ivu-form-item {
  margin-bottom: 0;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

.stock_lack_list .ivu-table-overflowX {
  overflow-x: hidden;
}

/*//自动移滚动条样式*/
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, .2);
}

::-webkit-scrollbar-track {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, .1);
}
</style>
